"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "h-full" };
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "HocLoading",
  props: {
    noPadding: Boolean,
    showLoading: Boolean,
    status: {
      type: Object,
      default: new Status(StatusType.Idle)
    },
    loaderClass: {
      type: String,
      default: "relative"
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_AppLoading = _resolveComponent("AppLoading");
      return _openBlock(), _createElementBlock("div", null, [
        (_openBlock(), _createBlock(_Suspense, null, {
          fallback: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AppLoading, {
                class: _normalizeClass(__props.loaderClass)
              }, null, 8, ["class"])
            ])
          ]),
          default: _withCtx(() => [
            __props.status.isLoading() || __props.showLoading ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["h-full", {
                "py-4": !__props.noPadding
              }])
            }, [
              _createVNode(_component_AppLoading, {
                class: _normalizeClass(__props.loaderClass)
              }, null, 8, ["class"])
            ], 2)) : _renderSlot(_ctx.$slots, "default", { key: 1 })
          ]),
          _: 3
        }))
      ]);
    };
  }
});
