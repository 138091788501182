"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "left-0 top-0 h-full w-full" };
export default /* @__PURE__ */ _defineComponent({
  __name: "Loading",
  props: {
    left: Boolean,
    right: Boolean
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_AssetLogoSpinner = _resolveComponent("AssetLogoSpinner");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["h-full w-full flex items-center", {
            "justify-start": __props.left,
            "justify-end": __props.right,
            "justify-center": !__props.left && !__props.right
          }])
        }, [
          _createVNode(_component_AssetLogoSpinner, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16)
        ], 2)
      ]);
    };
  }
});
